<template>
  <div>
    <b-modal
      id="personal-leave-entitlement-modal"
      :title="$t('LEAVE.APPLYNEW', { leave: form.leave.name })"
      title-class="h4 font-weight-bold"
      lazy
      size="lg"
      hide-footer
    >
      <PersonalLeaveEntitlementApplyLeaveForm
        class="mx-2 my-5"
        :is-busy="isBusy"
        @submit-clicked="submitForm"
      ></PersonalLeaveEntitlementApplyLeaveForm>
    </b-modal>
  </div>
</template>

<script>
import PersonalLeaveEntitlementApplyLeaveForm from "@/modules/personal/components/leave/entitlement/modal/PersonalLeaveEntitlementApplyLeaveForm";
export default {
  name: "PersonalLeaveEntitlementModal",
  components: { PersonalLeaveEntitlementApplyLeaveForm },
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    /**
     * Submit form via store.
     *
     * @param form
     */
    submitForm(form) {
      console.log("Submitting form", form);

      this.isBusy = true;
      this.$store
        .dispatch("submitPersonalLeaveApplicationForm", {
          form: {
            from_date: form.from,
            to_date: form.to,
            employee_id: 1,
            id: this.form.leave_id,
            remark: form.remark,
          },
        })
        .then((res) => {
          this.$bvModal.msgBoxOk(this.$t("ALERT.SUCCESS"));
          this.$emit("formSubmitted", res);
        })
        .catch(() => {
          this.$bvModal.msgBoxOk(this.$t("ALERT.ERROR"), {
            okVariant: "danger",
          });
        })
        .finally(() => (this.isBusy = false));
    },
  },
  computed: {},
};
</script>

<style scoped></style>
