<template>
  <div>
    <b-table
      ref="leave-entitlement-page-dt"
      id="leave-entitlement-page-dt"
      responsive="sm"
      :fields="fields"
      :items="items"
      table-class="table-head-custom table-vertical-center mt-5"
      :busy="isBusy"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showEntitledLeaveApplication(data)"
            v-b-tooltip.hover="$t('LEAVE.APPLY')"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
            </span>
          </button>

          <!-- end::: edit-employee button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>
    </b-table>
    <PersonalLeaveEntitlementModal :form="form"></PersonalLeaveEntitlementModal>

    <div class="d-flex justify-content-end" v-if="items.length || !isBusy">
      <b-pagination
        pills
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        aria-controls="leave-entitlement-page-dt"
        @change="handlePageChange"
        size="lg"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import PersonalLeaveEntitlementModal from "@/modules/personal/components/leave/entitlement/modal/PersonalLeaveEntitlementModal";

export default {
  name: "PersonalLeaveApplication",
  props: ["reload"],
  components: {
    PersonalLeaveEntitlementModal,
    AppLoader,
  },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "#" },
        { key: "name", label: this.$t("ENTITLEMENT.TYPE") },
        { key: "days", label: this.$t("ENTITLEMENT.DAYS") },
        { key: "balance", label: this.$t("ENTITLEMENT.BALANCE") },
        { key: "actions", label: this.$t("BUTTON.APPLY") },
      ],
      isBusy: true,
    };
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchPersonalLeaveEntitlementList")
        .finally(() => (this.isBusy = false));
    },
    handlePageChange(value) {
      console.log(value);
      console.log("Fetching ...");
      this.$store.commit("setPersonalLeaveEntitlementListPage", value);
      this.fetch();
    },
    showEntitledLeaveApplication(data) {
      console.log("Selected Leave ", data.item);
      this.$store.dispatch("assignEntitledLeaveApplication", data.item);
      this.$store.dispatch("fetchPersonalLeaveEntitlementForm", {
        id: data.item.id,
      });

      this.$bvModal.show("personal-leave-entitlement-modal");
    },
  },

  computed: {
    ...mapGetters({
      items: "getPersonalLeaveEntitlementList",
      pagination: "getPersonalLeaveEntitlementListPagination",
      form: "getPersonalLeaveEntitlementForm",
    }),
  },
};
</script>

<style scoped></style>
